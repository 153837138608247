<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.order_column") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <el-button
          v-can="'orders.create'"
          size="mini"
          @click="drawerCreate = true"
          icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>

          <!-- Нужно переименовать статусы ниже -->
          <crm-th
            :column="columns.is_report"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>

          <crm-th
            :column="columns.is_conclusion"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>

          <crm-th
            :column="columns.is_register"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <!-- Нужно переименовать статусы выше -->

          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.is_report.show">
            <!-- <el-input
              size="mini"
              v-model="filterForm.is_report"
              :placeholder="columns.is_report.title"
              clearable
            >
            </el-input> -->
          </th>
          <th v-if="columns.is_conclusion.show">
            <!-- <el-input
              size="mini"
              v-model="filterForm.is_conclusion"
              :placeholder="columns.is_conclusion.title"
              clearable
            >
            </el-input> -->
          </th>
          <th v-if="columns.is_register.show">
            <!-- <el-input
              size="mini"
              v-model="filterForm.is_register"
              :placeholder="columns.is_register.title"
              clearable
            >
            </el-input> -->
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="conditions in list"
          :key="conditions.id"
          class="cursor-pointer"
        >
          <td v-if="columns.id.show">{{ conditions.id }}</td>
          <td v-if="columns.name.show">{{ conditions.name }}</td>
          <td v-if="columns.is_report.show">
            {{ $t("message." + getAnswer(conditions.is_report)) }}
          </td>
          <td v-if="columns.is_conclusion.show">
            {{ $t("message." + getAnswer(conditions.is_conclusion)) }}
          </td>
          <td v-if="columns.is_register.show">
            {{ $t("message." + getAnswer(conditions.is_register)) }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ conditions.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ conditions.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="conditions"
              name="conditions"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      title="Новый тег"
      :wrapperClosable="false"
      :visible.sync="drawerCreate"
      size="60%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create @c-close="closeDrawer"></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      :wrapperClosable="false"
      :visible.sync="drawerUpdate"
      size="60%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
          @closed="emptyModel"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";

export default {
  mixins: [list],
  name: "ConditionsList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "orderColumn/list",
      columns: "orderColumn/columns",
      pagination: "orderColumn/pagination",
      filter: "orderColumn/filter",
      sort: "orderColumn/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "orderColumn/index",
      updateSort: "orderColumn/updateSort",
      updateFilter: "orderColumn/updateFilter",
      updateColumn: "orderColumn/updateColumn",
      updatePagination: "orderColumn/updatePagination",
      editModel: "orderColumn/show",
      empty: "orderColumn/empty",
      delete: "orderColumn/destroy",
      refreshData: "orderColumn/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    getAnswer(val) {
      let answer = val ? val : false;
      return answer ? "yes" : "no";
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
